import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Main from './components/main/main';
import Login from './components/main/login';
import Admin from './components/main/admin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrizeProvider } from './components/main/PrizeContext'; // Import PrizeProvider

function App() {
  const isAdmin = localStorage.getItem("isAdmin");

  return (
    <div className="App">
      <PrizeProvider> {/* Bọc PrizeProvider */}
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin"
              element={isAdmin ? <Admin /> : <Navigate to="/login" />}
            />
          </Routes>
        </Router>
      </PrizeProvider>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
}

export default App;
