import React, { useState, useEffect, useRef } from "react";
import "./main.css";
import { toast } from "react-toastify"; 
import { Fireworks } from "fireworks-js"; // Import thư viện pháo hoa

const Main = () => { 
  const [isRegistered, setIsRegistered] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(true);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [codeUsed, setCodeUsed] = useState(false);
  const [giftCode, setGiftCode] = useState("");
  const [btnWheelDisabled, setBtnWheelDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [listGift, setListGift] = useState([]); // Lấy danh sách giải thưởng từ API
  const wheelRef = useRef(null);
  const fireworksRef = useRef(null); // Tạo ref cho container pháo hoa
  const timeRotate = 7000;

  const size = listGift.length;
  const rotate = size > 0 ? 360 / size : 0;
  const skewY = 90 - rotate;
  const randomNumber = [
    1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 3, 4, 5, 6, 7, 8, 9,
  ];
  
  const getRandomNumber = () => {
    const randomIndex = Math.floor(Math.random() * randomNumber.length);
    return randomNumber[randomIndex];
  }; 

  const startFireworks = () => {
    if (!fireworksRef.current) return; // Kiểm tra nếu fireworksRef đã có giá trị
    
    const fireworks = new Fireworks(fireworksRef.current, {
      hue: { min: 0, max: 345 },
      delay: { min: 10, max: 15 },
      speed: 2,
      acceleration: 1.05,
      friction: 0.98,
      gravity: 1.5,
      particles: 150,
      trace: 3,
      explosion: 5,
      boundaries: {
        x: 50,
        y: 50,
        width: fireworksRef.current.clientWidth,
        height: fireworksRef.current.clientHeight,
      },
      sound: {
        enabled: false, // Tắt âm thanh để tránh lỗi
      },
    });
  
    fireworks.start();
    setTimeout(() => {
      fireworks.stop(); // Dừng pháo hoa sau một khoảng thời gian
    }, 3000);
  };

  const fetchGiftList = async () => {
    try {
      const response = await fetch("https://api-to6.vongquayvip.vip/api/prizes");
      const data = await response.json();
      console.log("Fetched prize data:", data);
      setListGift(data);
    } catch (error) {
      toast.error("Lỗi khi tải danh sách giải thưởng.");
    }
  };
  useEffect(() => {
    if (isRegistered) {
      fetchGiftList();
    }
  }, [isRegistered]);

  useEffect(() => {
    const wheel = wheelRef.current;
    if (!wheel || listGift.length === 0) return;

    setTimeout(() => {
      wheel.innerHTML = ""; // Xóa các phần tử cũ trước khi thêm phần tử mới
      listGift.forEach((item, index) => {
        const elm = document.createElement("li");
        elm.style.transform = `rotate(${rotate * index}deg) skewY(-${skewY}deg)`;
        elm.innerHTML = `<p style="transform: skewY(${skewY}deg) rotate(${
          rotate / 2
        }deg);" class="text text-${(index % 7) + 1}"><b>${item.text}</b></p>`;
        wheel.appendChild(elm);
      });
    }, 100);
  }, [listGift, rotate, skewY]);

  const sendTelegramMessage = async (message) => {
    const telegramBotToken = "7410284295:AAGhw39vBfxMleq0Nmji6JBpts3t5k8r5zA"; // Thay thế bằng token bot của bạn
    const chatId = "-4565097293"; // Thay thế bằng chat ID của bạn
  
    try {
      const response = await fetch(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message,
        }),
      });
  
      const data = await response.json();
  
      if (data.ok) {
        console.log("Message sent to Telegram successfully!");
      } else {
        console.error("Error sending message to Telegram:", data);
      }
    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
  };
  const handleInfoSubmit = () => {
    if (userName && userEmail) {
      setShowInfoPopup(false);
      setIsRegistered(true); // Bật isRegistered để kích hoạt fetchGiftList
      toast.success("Thông tin đã được xác nhận!");
  
      const message = `Thông tin người dùng đăng ký:\nHọ và tên: ${userName}\nSố điện thoại: ${userEmail}`;
      sendTelegramMessage(message);
    } else {
      toast.error("Vui lòng nhập đầy đủ tên và email!");
    }
  };
  

  const rotateWheel = (currentRotate, index) => {
    const wheel = wheelRef.current;
    wheel.style.transform = `rotate(${currentRotate + 90 - index * rotate - rotate / getRandomNumber()}deg)`;
  };

  const getGift = (randomNumber) => {
    let currentPercent = 0;
    let gift = null;

    listGift.forEach((item, index) => {
      currentPercent += item.percent;
      if (!gift && randomNumber <= currentPercent) {
        gift = { ...item, index };
      }
    });
    return gift;
  };

  const showGift = (gift) => {
    setTimeout(() => {
      setIsRotating(false);
      setShowMsg(`Phần thưởng: "${gift.text}"`);
      setBtnWheelDisabled(true);
      setCodeUsed(true);
      setGiftCode("");
      setShowModal(true);
      
      startFireworks(); // Chỉ gọi sau khi showModal đã được thiết lập
    }, timeRotate);
  };

  const start = () => {
    setShowMsg("");
    setIsRotating(true);
    const random = Math.random();
    const gift = getGift(random);
  
    if (!gift) { // Kiểm tra nếu gift là null hoặc undefined
      console.error("Không tìm thấy phần thưởng nào phù hợp với randomNumber.");
      setIsRotating(false); // Dừng vòng quay nếu không có gift
      return;
    }
  
    const fullRotate = 360 * 10;
    rotateWheel(fullRotate, gift.index);
    showGift(gift);
  };

  const handleConfirmClick = async () => {
    try {
      const response = await fetch("https://api-to6.vongquayvip.vip/api/check-gift-code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: giftCode }),
      });

      const data = await response.json();

      if (response.ok) {
        setBtnWheelDisabled(false);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error checking gift code:", error);
      toast.error("Có lỗi xảy ra khi xác thực mã quà tặng.");
    }
  };

  const handleWheelClick = () => {
    if (!isRotating) {
      start();
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload();
  };

  return (
    <div> 
      <div ref={fireworksRef} className="fireworks-container" style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', pointerEvents: 'none' }}></div>
      {showInfoPopup && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="titl">
              Đăng Ký Thông Tin Ngay Để Tham Gia Quay Thưởng Nhận Quà Nhé<i className="fa fa-smile-o" aria-hidden="true"></i> 
              <p style={{fontSize:'0.7em'}}> Lưu ý : Điền thông tin chính xác để tham gia vòng quay nhận thưởng ngay nhé </p>
            </h2> 
       
            <div className="form">
              <input
                className="input-form"
                type="text"
                placeholder="Họ và Tên"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <input
                className="input-form"
                type="phone"
                placeholder="Số Điện Thoại Của Bạn"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <button className="button-form" onClick={handleInfoSubmit}>Xác nhận</button>
            </div>
          </div>
        </div>
      )}

      {!showInfoPopup && (
        <section className="main">
          <h1 className="title">VÒNG QUAY MAY MẮN - PHÁT LỘC - PHÁT TÀI</h1>
          <div>
            <ul className="wheel" ref={wheelRef}></ul>
            <span className="pointer"></span>
          </div>
          <div className="group-wheel">
            <input
              type="text"
              className="gift-code"
              placeholder="Nhập gift code"
              value={giftCode}
              onChange={(e) => setGiftCode(e.target.value)}
            />
            <button className="btn--confirm" onClick={handleConfirmClick}>
              Xác nhận
            </button>
          </div>
          <button
            className="btn--wheel"
            disabled={btnWheelDisabled}
            onClick={handleWheelClick}
          >
            Quay thưởng
          </button>
        </section>
      )}



      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2> <p style={{color:'red'}}> Chúc Mừng Bạn Đã Trúng {showMsg}  </p> <br/> <p>  chụp màn hình lại và gửi cho admin để nhận quà nhé </p> </h2>
            <button onClick={handleModalClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
}; 

export default Main;
