// PrizeContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const PrizeContext = createContext();

export const usePrizes = () => {
  return useContext(PrizeContext);
};

export const PrizeProvider = ({ children }) => {
  const [prizes, setPrizes] = useState([]);

  // Lấy danh sách giải thưởng từ server khi component được mount
  useEffect(() => {
    const fetchPrizes = async () => {
      try {
        const response = await fetch("https://api-to6.vongquayvip.vip/api/prizes");
        const data = await response.json();
        setPrizes(data);
      } catch (error) {
        console.error("Error fetching prizes:", error);
      }
    };
    fetchPrizes();
  }, []);

  // Hàm cập nhật giải thưởng
  const updatePrizes = async (newPrizes) => {
    setPrizes(newPrizes);
    try {
      await fetch("https://api-to6.vongquayvip.vip/api/update-prizes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prizes: newPrizes }),
      });
    } catch (error) {
      console.error("Error updating prizes:", error);
    }
  };

  return (
    <PrizeContext.Provider value={{ prizes, updatePrizes }}>
      {children}
    </PrizeContext.Provider>
  );
};
