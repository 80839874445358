import React from "react";
import { usePrizes } from "./PrizeContext";
import { toast } from "react-toastify";

const Admin = () => {
  const { prizes, updatePrizes } = usePrizes();

  const handlePrizeChange = (index, field, value) => {
    const updatedPrizes = prizes.map((prize, i) =>
      i === index ? { ...prize, [field]: value } : prize
    );
    updatePrizes(updatedPrizes);
  };

  const handleSaveChanges = () => {
    toast.success("Đã lưu thay đổi!");
    updatePrizes(prizes);
  };

  return (
    <div className="admin-page">
      {prizes.map((prize, index) => (
        <div className="adz" key={index} style={{ marginBottom: "1rem",display:'flex', width:'100%', }}>
          <div style={{width:'25%'
          }}>
          <input
            type="text"
            value={prize.text}
            onChange={(e) => handlePrizeChange(index, "text", e.target.value)}
            placeholder="Tên giải thưởng"
          /> 
           </div>
           <div style={{width:'25%'
          }}> 
          <input
            type="number"
            value={prize.percent}
            onChange={(e) => handlePrizeChange(index, "percent", Number(e.target.value))}
            placeholder="Phần trăm trúng"
          />
          </div>
        </div>
      ))}
      <button onClick={handleSaveChanges}>Lưu thay đổi</button>
    </div>
  );
};

export default Admin;
